import React from "react";
import ProductCard from "../../Components/Common/ProductCard";
import FeaturesCard from "../../Components/Common/FeaturesCard";
import cloudPlatform from "../../assets/Features/cloudPlatform.jpg";
// import featureRich from "../../assets/Features/featureRich.jpg";
import payOnUse from "../../assets/Features/payOnUse.jpg";
// import digitalSolution from "../../assets/Features/digitalSolution.jpg";
import AnalyticsDashboard from "../../assets/Features/AnalyticsDashboard.jpg";
import enterpriseSolution from "../../assets/Features/enterpriseSolution.jpg";
import automation from "../../assets/Features/automation.jpg";
import externalIntegration from "../../assets/Features/externalIntegration.jpg";
import subscriptionOffer from "../../assets/Features/subscriptionOffer.jpg";

const Solutions = () => {
  return (
    <div className="flex flex-col gap-5 lg:gap-10 desktop:gap-20 w-full text-white px-5 lg:px-10">
      <h1 className="text-highlight text-4xl lg:text-6xl desktop:text-7xl text-center">Solutions</h1>
      <div className="flex flex-col lg:flex-row justify-center gap-5 lg:gap-10 desktop:gap-20">
        {/* <ProductCard heading={"ANNAPURNA"} description={"ANNAPURNA is a future and must-have digital platform for modern restaurants. It provides various digital services like QR digitised menu, Order Manangement, Kitchen and Captain Dashboard, Digital Invocing, Order Analytics and much more"}>
          <FeaturesCard imgPath={featureRich} imgName={"Feature Rich"} />
          <FeaturesCard imgPath={cloudPlatform} imgName={"Cloud Platform"} />
          <FeaturesCard imgPath={payOnUse} imgName={"Pay on use"} />
          <FeaturesCard imgPath={digitalSolution} imgName={"Digital Solution"} />
        </ProductCard> */}
        <ProductCard heading={"Energy Invoicing Portal"} description={"Energy Invocing Portal caters to the needs of electricity power purchase. It provides automated way of invoice generation via integration with various external entities. Various types of invoces are generated based power purchase agreements and changing power procement laws."}>
          <FeaturesCard imgPath={automation} imgName={"Automation"} />
          <FeaturesCard imgPath={AnalyticsDashboard} imgName={"Analytics Dashboard"} />
          <FeaturesCard imgPath={externalIntegration} imgName={"External Integrations"} />
          <FeaturesCard imgPath={enterpriseSolution} imgName={"Enterprise Solution"} />
          <FeaturesCard imgPath={subscriptionOffer} imgName={"Subscription offer"} />
        </ProductCard>
      </div>
      <div className="flex flex-col lg:flex-row justify-center gap-5 lg:gap-10 desktop:gap-20">
        <ProductCard heading={"PassGuard"} description={"PassGaurd is a digital lock to store the passwords and frequently used credentials. It is onpremise and on cloud security solution to manage sensitive information and share across organization in the secure way"}>
          <FeaturesCard imgPath={automation} imgName={"Security Solution"} />
          <FeaturesCard imgPath={cloudPlatform} imgName={"Cloud Platform"} />
          <FeaturesCard imgPath={payOnUse} imgName={"Pay on use"} />
          <FeaturesCard imgPath={enterpriseSolution} imgName={"Enterprise Solution"} />
        </ProductCard>
      </div>
    </div>
  );
};

export default Solutions;
